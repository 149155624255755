import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';  // Import React Helmet
import PitchFinder from 'pitchfinder';

function Tuner() {
    const [detectedNote, setDetectedNote] = useState({ note: 'C4', frequency: null, errorInCents: 0 });
    const [currentDb, setCurrentDb] = useState(null);
    const [circleFillPercentage, setCircleFillPercentage] = useState(0);
    const [stabilityTimer, setStabilityTimer] = useState(null);

    const MIN_DB_THRESHOLD = -50; // Minimum dB level for note detection

    useEffect(() => {
        const detectPitch = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const sampleRate = audioContext.sampleRate;

                const source = audioContext.createMediaStreamSource(stream);
                const analyser = audioContext.createAnalyser();
                source.connect(analyser);
                analyser.fftSize = 2048;
                const bufferLength = analyser.frequencyBinCount;
                const dataArray = new Float32Array(bufferLength);
                const pitchDetector = PitchFinder.YIN({ sampleRate: sampleRate });

                const detect = () => {
                    analyser.getFloatTimeDomainData(dataArray);
                    const rms = Math.sqrt(dataArray.reduce((acc, val) => acc + val * val, 0) / dataArray.length);
                    const db = 20 * Math.log10(rms);
                    setCurrentDb(db);

                    if (db > MIN_DB_THRESHOLD) {
                        const frequency = pitchDetector(dataArray);

                        if (frequency) {
                            const noteInfo = frequencyToNoteName(frequency);

                            if (Math.abs(noteInfo.errorInCents) <= 10) {
                                if (!stabilityTimer) {
                                    const timer = setTimeout(() => {
                                        setCircleFillPercentage(100);
                                    }, 10000);
                                    setStabilityTimer(timer);
                                }
                            } else {
                                clearTimeout(stabilityTimer);
                                setStabilityTimer(null);
                                setCircleFillPercentage(0);
                            }

                            setDetectedNote(noteInfo);
                        } else {
                            clearTimeout(stabilityTimer);
                            setStabilityTimer(null);
                            setCircleFillPercentage(0);
                            setDetectedNote({ note: 'C4', frequency: null });
                        }
                    } else {
                        setDetectedNote({ note: 'C4', frequency: null });
                    }

                    requestAnimationFrame(detect);
                };

                detect();
            } catch (error) {
                console.error('Error accessing the microphone', error);
                setDetectedNote({ note: 'C4', frequency: null });
            }
        };

        detectPitch();
    }, []);

    // Convert frequency to note, using 440 Hz for A4
    function frequencyToNoteName(frequency) {
        const noteStrings = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
        const a4 = 440;  // Reference frequency for A4 is 440 Hz
        const halfStepsAboveA4 = 12 * Math.log2(frequency / a4);
        const noteNumber = Math.round(halfStepsAboveA4) + 9;  // A4 is the 9th note in the scale
        const octave = Math.floor(noteNumber / 12) + 4;
        const noteIndex = (noteNumber % 12 + 12) % 12;
        const noteName = noteStrings[noteIndex];

        const exactFrequency = a4 * Math.pow(2, (noteNumber - 9) / 12);
        const errorInCents = 1200 * Math.log2(frequency / exactFrequency);  // Calculate error in cents

        return { 
            note: `${noteName}${octave}`, 
            frequency, 
            errorInCents 
        };
    }

    // Calculate the angle for the indicator (90° when in tune, -90° to +90° range)
    const calculateIndicatorAngle = (errorInCents) => {
        const maxCents = 50; // +/-50 cents range
        const clampedError = Math.max(-maxCents, Math.min(maxCents, errorInCents));
        return (clampedError / maxCents) * 90; // Scale to -90° to +90°
    };

    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
        {/* React Helmet for dynamic SEO */}
        <Helmet>
                <title>Metronome Buddy Tuner | Online Instrument Tuner</title>
                <meta name="description" content="Metronome Buddy Tuner is an accurate online tuner for musicians to fine-tune instruments like guitar and violin with real-time pitch detection." />
                <meta name="keywords" content="tuner, online tuner, guitar tuner, violin tuner, Metronome Buddy tuner, pitch detection" />
                <link rel="canonical" href="https://metronomebuddy.com/tuner" />
                
                {/* Open Graph for social sharing */}
                <meta property="og:url" content="https://metronomebuddy.com/tuner" />
                  {/* FAQ Structured Data */}
                  <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": [
                      {
                        "@type": "Question",
                        "name": "How accurate is Metronome Buddy Tuner?",
                        "acceptedAnswer": {
                          "@type": "Answer",
                          "text": "Metronome Buddy Tuner is extremely accurate and designed for musicians to tune their instruments perfectly."
                        }
                      },
                      {
                        "@type": "Question",
                        "name": "Can I tune a guitar with Metronome Buddy?",
                        "acceptedAnswer": {
                          "@type": "Answer",
                          "text": "Yes, Metronome Buddy’s tuner supports tuning for guitar, violin, wind instruments, and more."
                        }
                      },
                      {
                        "@type": "Question",
                        "name": "Is Metronome Buddy Tuner free?",
                        "acceptedAnswer": {
                          "@type": "Answer",
                          "text": "Yes, Metronome Buddy Tuner is completely free to use for musicians of all levels."
                        }
                      }
                    ]
                  }
                `}
                </script>
            </Helmet>

            {/* Main Container for Note and Pitch */}
            <div className="outt" style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '768px', margin: '0 auto', alignItems: 'center' }}>
                
                {/* Detected Note and Frequency */}
                <div className="tuning" style={{ flex: 1, textAlign: 'center', minWidth: '250px', minHeight: '150px' }}>
                    <div style={{ fontSize: '48px', marginBottom: '10px', color: '#fff', height: '60px' }}>
                        {detectedNote.note}
                    </div>
                    <div style={{ fontSize: '24px', color: '#EA6518' }}>
                        {detectedNote.frequency !== null ? `${detectedNote.frequency.toFixed(2)} Hz` : 'Waiting for sound...'}
                    </div>
                </div>

                {/* Tuning Indicator */}
                <div className="freq" style={{ flex: 1, textAlign: 'center', minWidth: '250px', minHeight: '150px' }}>
                    <div className="gauge-container" style={{ position: 'relative', width: '200px', height: '100px', margin: '0 auto' }}>
                        <div className="gauge" style={{
                            width: '100%',
                            height: '100%',
                            borderBottom: '2px solid rgba(128,0,128,1)',
                            borderLeft: '2px solid rgba(128,0,128,1)',
                            borderRight: '2px solid rgba(128,0,128,1)',
                            borderRadius: '100% 100% 0 0',
                            position: 'relative',
                            background: 'rgba(128,0,128,1)' , // Dark background for the gauge
                            background: 'rgb(128,0,128)',
                            background: 'linear-gradient(0deg, rgba(128,0,128,1) 0%, rgba(191,36,255,1) 100%)'
                        }}>
                            {/* Arrow Indicator */}
                            <div className="gauge-arrow" style={{
                                width: '3px',
                                height: '80px',
                                backgroundColor: '#fff',  // Orange arrow color
                                position: 'absolute',
                                bottom: '0',
                                left: '50%',
                                transformOrigin: 'bottom center',
                                transform: `rotate(${calculateIndicatorAngle(detectedNote.errorInCents || 0)}deg)`,
                                transition: 'transform 0.1s ease-out',
                            }}></div>
                        </div>
                    </div>

                    {/* Tuning Text */}
                    <div style={{ marginTop: '20px', color: detectedNote.errorInCents <= 5 ? 'green' : '#EA6518', fontSize: '18px' }}>
                        {detectedNote.frequency !== null && Math.abs(detectedNote.errorInCents) <= 5 ? '🎯 In Tune' : 'Adjust Pitch'}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tuner;
