import React from 'react';
import './App.css';
import Metronome from './Metronome';
import Tuner from './Tuner';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="tabs">
          <Link to="/" className="pushable">
            <span className="front">Metronome</span>
          </Link>
          <Link to="/tuner" className="pushable">
            <span className="front">Tuner</span>
          </Link>
        </div>
        <main>
          <Routes>
            <Route path="/" element={<Metronome />} />
            <Route path="/tuner" element={<Tuner />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
